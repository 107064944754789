import styles from './OfferCard.module.scss';
import cn from 'classnames';
import { ImageWithFallback } from '../ImageWithFallback/ImageWithFallback';
import { Box, Button, Chip, createTheme, Dialog, Skeleton, TextField, Tooltip } from '@material-ui/core';
import { setImageUrl } from '../../utils/setImageUrl';
import { getCabinClass } from '../FlightSearchResults/utils/flightUtils';
import CurrencyFormat from 'react-currency-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import parse from 'html-react-parser';
import CloseIcon from '@mui/icons-material/Close';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import IosShareIcon from '@mui/icons-material/IosShare';
import DiscountIcon from '@mui/icons-material/Discount';
import { useWindowWidth } from '@react-hook/window-size';
import { createRef, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { CABIN_CLASSES } from '../../utils/cabinClasses';
import { getTextEditorParseOptions } from '../utils/textEditorParseOptions';
import InView from 'react-intersection-observer';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { httpGet } from '../../utils/httpClient';
import qs from 'qs';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { OfferChart } from './OfferChart';
// import { FeaturedFlightsFilters } from '../FeaturedFlights/FeaturedFlightsFilters/FeatredFlightsFilters';

const FlightSearch = dynamic(() => import('../FlightSearch/FlightSearch'));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#3a8589',
  },
}));

const monthesList = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

const numberOfVisibleDays = 330;
const PAGE_SIZE = 8;

const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
    color: '#0a0a0a',
  },
});

const getSimilarOffers = async (filterObj) => {
  let filterUrl = '';
  Object.keys(filterObj).forEach((key) => {
    if (filterObj[key]) {
      filterUrl += `&${key}=${filterObj[key]}`;
    }
  });
  if (filterUrl.charAt(0) === '&') {
    filterUrl = filterUrl.slice(1);
  }

  const url = `/api/flight/v2/offers?${filterUrl}&size=40&sort=price,asc`;

  return await (await httpGet(url)).json();
};

export function OfferCardMerged({ data, searchSource, isOpened=false, type = 'card', showOrigin = false, message = '', closeDetails = () => {}}) {
  const [isOfferDialogOpen, setIsOfferDialogOpen] = useState(false);
  const width = useWindowWidth();
  const router = useRouter();
  const [mediaHeight, setMediaHeight] = useState(0);
  const [mediaWidth, setMediaWidth] = useState(0);
  const [similarOffers, setSimilarOffers] = useState(null);
  const [offer, setOffer] = useState(null);
  const [shareDialogState, setShareDialogState] = useState({ open: false, offer: null });
  const [dataSets, setDataSets] = useState(null);
  const [clickedBarMonth, setClickedBarMonth] = useState(null);
  const [isSimilarOffersLoading, setIsSimilarOffersLoading] = useState(false);
  const [isEmptyMonthClicked, setIsEmptyMonthClicked] = useState(false);
  // const [selectedOffersFilters, setSelectedOffersFilters] = useState(null);
  // const [featuredMaxBudget, setFeaturedMaxBudget] = useState(0);
  // const [featuredMinBudget, setFeaturedMinBudget] = useState(0);
  const [visibleCount, setVisibleCount] = useState(PAGE_SIZE);
  const [isBarChartShown, setIsBarChartShown] = useState(false);
  const imageRef = createRef();
  const userStore = useSelector((state) => state.user.value);

  const visibleOffers = similarOffers?.offers?.slice(0, visibleCount);


  useEffect(() => {
    if (imageRef?.current) {
      setMediaHeight(imageRef.current.offsetWidth / 1.78);
      setMediaWidth(imageRef.current.offsetWidth);
    }
  }, [imageRef]);

  useEffect(() => {
    if (isOpened) {
      setIsOfferDialogOpen(true);
      if (data) {
        openOfferDetails(data);
      }
    }
  }, [isOpened, data]);

  useEffect(() => {
    if (!data) return;
    const offer = {
      id: data.idOffer,
      price: data.price || data.lowTotal,
      regularPrice: data.regularPrice?.toFixed(0) || null,
      discount: data.discount || 0,
      cabinClass: data.cabinClass,
      currency: data.currency || userStore?.currency,
      link: "?searchRedirect=true",
      country: data.country || null,
      content: data.content,
      expirationDate: data.expirationDate || null,
      departureDate: data.departureDate,
      returnDate: data.returnDate,
      searchDate: data.searchDate,
      origin: { ...data.origin },
      destination: { ...data.destination, region: data.destination?.region || null },
      airline: { ...data.airline },
      lowTotal: data.lowTotal,
      lowTotalAirline: data.lowTotalAirline,
      isDeal: data.isDeal,
      idOffer: data.idOffer,
      similarOffers: data.similarOffers
    };
    setOffer({...offer});
  }, [data]);

  const options = getTextEditorParseOptions({
    article: {},
    mediaWidth,
    mediaHeight,
    imageRef,
    styles,
  });

  const isFlightSearchLink = (offerLink) => {
    if (!offerLink) {
      return false;
    }
    return offerLink.includes('searchRedirect');
  };

  const closeOfferDialog = () => {
    setIsOfferDialogOpen(false);
    const query = { ...router.query };
    Object.keys(router.query).forEach((key) => {
      if (
        [
          'offerid',
          'origin',
          'destination',
          'country',
          'currency',
          'cabinClass',
          'airline',
          'price',
          'date',
          'rdate',
        ].includes(key)
      ) {
        delete query[key];
      }
    });

    sessionStorage.setItem('BC-offerIDOpened', null);
    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true }
    );
    closeDetails(true);
  };

  function sendFlightOptionsClickEvent(offer, cabinClass) {
    const tagManagerArgs = {
      dataLayer: {
        event: 'offerClick',
        flightCabinClass: getCabinClass(cabinClass, true),
        userCountry: userStore?.country.toUpperCase(),
        airlineName: offer.airline.name,
        destinationCity: offer.destination.name,
        originCity: offer.origin.name,
        membershipId: userStore?.membershipNumber,
        traffic_type: userStore?.is_admin ? 'internal' : 'external',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
};

function getOfferUrl(offer) {
  if (!offer) {
    return '';
  }

  return `?country=${userStore.country}&origin=${offer.origin.code}&destination=${offer.destination.code}&cabinClass=${offer.cabinClass}&date=${offer.departureDate}&rdate=${offer.returnDate}&price=${offer.price}`;
}

function createChartDataset(flights) {
  const labels = [];
  const values = [];
  const backgroundColors = [];
  const noPriceValues = [];

  if (!flights || !flights.length) {
    const noPricevalue = 100;
    const currentMonthIndex = new Date().getMonth();
    const sortedMonthesList = [
      ...monthesList.slice(currentMonthIndex),
      ...monthesList.slice(0, currentMonthIndex)
    ];

    sortedMonthesList.forEach((month, i) => {
      let monthIndex = i + 1 + currentMonthIndex;
      if (monthIndex > 12) {
        monthIndex -= 12;
      }

      labels.push(
        month
      );
      backgroundColors.push('rgba(194, 194, 194, 0.8)');
      values.push(null);
      noPriceValues.push(noPricevalue);
    });
  } else {
    flights.sort((a, b) => a.year - b.year || a.monthNumber - b.monthNumber );
    const avaragePrice = Math.round(flights.reduce((a, b) => a + b.lowTotal, 0) / flights.length);

    const currentMonthIndex = new Date().getMonth();
    const maxMonthToShowIndex = moment().add(numberOfVisibleDays, 'days').get('month');
    
    let sortedMonthesList = [
      ...monthesList.slice(currentMonthIndex),
      ...monthesList.slice(0, currentMonthIndex)
    ];

    const monthIndexToDeleteFrom = sortedMonthesList.findIndex(m => m === monthesList[maxMonthToShowIndex]);

    sortedMonthesList = sortedMonthesList.slice(0, monthIndexToDeleteFrom);

    sortedMonthesList.forEach((month, i) => {
      let monthIndex = i + 1 + currentMonthIndex;
      if (monthIndex > 12) {
        monthIndex -= 12;
      }

      labels.push(
        month
      );
      const currentMonthFlight = flights.find(flight => flight.monthNumber === monthIndex);
      if (currentMonthFlight) {
        if (currentMonthFlight.lowTotal < avaragePrice * 1.5) {
          backgroundColors.push('rgba(212, 169, 118, 0.8)');
          const value = Math.round(currentMonthFlight.lowTotal);
          values.push(value);
          noPriceValues.push(null);
        } else {
          backgroundColors.push('rgba(194, 194, 194, 0.8)');
          values.push(null);
          noPriceValues.push(avaragePrice);
        } 
        
      } else {
        const noPricevalue = avaragePrice;
        backgroundColors.push('rgba(194, 194, 194, 0.8)');
        values.push(null);
        noPriceValues.push(noPricevalue);
      }
    });
  }

  // flights.forEach(flight => {
  //   labels.push(
  //     monthesList[flight.monthNumber - 1]
  //   );
  //   values.push(Math.round(flight.lowTotal));
  // });

  const dataset = {
    labels: labels,
    datasets: [
      {
        label: 'Price from',
        data: values,
        categoryPercentage: 0.9,
        skipNull: true,
        backgroundColor: 'rgba(212, 169, 118, 0.8)',
      },
      {
        label: 'No price',
        data: noPriceValues,
        categoryPercentage: 0.9,
        skipNull: true,
        backgroundColor: 'rgba(194, 194, 194, 0.8)',
      }
    ]
  };

  return dataset;
}

function msToTime(duration) {
  const minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor(duration / (1000 * 60 * 60));
  return { hours, minutes };
}

function hoursAgo(searchDate) {
  if (!searchDate) {
    return null;
  }

  const diff = msToTime(
    new Date() -
      new Date(
        searchDate?.replace(' ', 'T')?.replace(' UTC', '')?.split('.')[0] +
          'Z'
      )
  );

  const minutes = diff.minutes;
  const hours = diff.hours;

  if (hours > 48) {
    return '2 days ago';
  }
  if (hours > 24) {
    return '1 day ago';
  }
  if (hours >= 1) {
    if (hours > 1) {
      return hours + ' hours ago';
    } else {
      return hours + ' hour ago';
    }
  } else {
    if (minutes === 1) {
      return minutes + ' minute ago';
    } else if (minutes === 0) {
      return ' just now';
    } else {
      return minutes + ' minutes ago';
    }
  }
}

const handleMonthClick = (elements) => {
  if (elements && elements.length > 0) {
    const clickedBarIndex = elements[0].index;
    const month = dataSets.labels[clickedBarIndex];
    let monthIndex = monthesList.findIndex(m => m === month);

    console.log(elements);
    if (elements[0].datasetIndex === 1) {
      setIsEmptyMonthClicked(true);
      setSimilarOffers(Object.assign({}, {offers: []}));
      return;
    }

    setSimilarOffers(Object.assign({}, {offers: []}));
    // setSelectedOffer(selectedOffer);
    // setClickedBarMonth(null);
    setClickedBarMonth(monthesList[monthIndex]);

    if (isEmptyMonthClicked) {
      setIsEmptyMonthClicked(false);
    }

    let year = moment().year();
    let endDateMonth = monthIndex + 2;
    if (clickedBarIndex > monthIndex) {
      year++;
    }
    const startDate = moment(`${year}-${monthIndex + 1}-01`, 'YYYY-MM-DD').format('YYYY-MM-DD');

    if (endDateMonth > 12) {
      year++;
      endDateMonth = 1;
    }

    const endDate = moment(`${year}-${endDateMonth}-01`, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD');

    setIsSimilarOffersLoading(true);

    getSimilarOffers({
      origin: offer.origin.code,
      destination: offer.destination.code,
      cabinClass: offer.cabinClass,
      currency: userStore?.currency,
      gtedate: startDate,
      ltdate: endDate,
    }).then((res) => {
      setSimilarOffers(res);
      setIsSimilarOffersLoading(false);
      const similarOffers = document.getElementById('similarOffers');
      if (similarOffers) {
        similarOffers.scrollIntoView({ behavior: 'smooth' });
      }
    }).catch(() => {
      setIsSimilarOffersLoading(false);
    });
  } else {
    setClickedBarMonth(null);
    getSimilarOffers({
      origin: offer.origin.code,
      destination: offer.destination.code,
      cabinClass: offer.cabinClass,
      currency: userStore?.currency,
    }).then((res) => {
      setSimilarOffers(res);
      setIsSimilarOffersLoading(false);
      const similarOffers = document.getElementById('similarOffers');
      if (similarOffers) {
        similarOffers.scrollIntoView({ behavior: 'smooth' });
      }
    }).catch(() => {
      setIsSimilarOffersLoading(false);
    });
  }
}

// function emitPopularDealsFilters(filters) {
//   console.log(filters);
// }

function startSearchFromOffer(offer) {
  let query = {};
  query.cabinClass = offer.cabinClass;
  query.adults = 1;
  query.qatarOnly = 0;
  let analyticsJourneys = [];
  analyticsJourneys = [
    {
      originCountry: userStore.country,
      originCity: offer.origin.name,
      // destinationCountry: values.to.CountryCode,
      destinationCity: offer.destination.name,
    },
    {
      // originCountry: values.to.CountryCode,
      originCity: offer.destination.code,
      destinationCountry: userStore.country,
      destinationCity: offer.origin.code,
    },
  ];
  query.segments = [
    {
      origin: offer.origin.code,
      destination: offer.destination.code,
      departure: offer.departureDate,
      exactAirport: false,
    },
    {
      origin: offer.destination.code,
      destination: offer.origin.code,
      departure: offer.returnDate,
      exactAirport: false,
    },
  ];

  const tagManagerArgs = {
    dataLayer: {
      event: 'flightSearch',
      flightType: 'round_trip',
      journeys: analyticsJourneys,
      numGuests: 1,
      numAdults: 1,
      userCountry: userStore?.country.toUpperCase(),
      pageType: router?.pathname,
      loginStatus: userStore.userid ? 'logged in' : 'not logged in',
      membershipId: userStore?.membershipNumber,
      searchSource: searchSource,
      traffic_type: userStore?.is_admin ? 'internal' : 'external',
    },
  };
  TagManager.dataLayer(tagManagerArgs);

  query.tripState = 'round_trip';
  query.dealSearch = 1;
  query.sortBy = 'price';

  window.open(decodeURI(`/flightsearch?${qs?.stringify(query)}`), '_blank');
  window.focus();
}

function isPriceMismached(offerPrice, lowTotalPrice) {
  if (!lowTotalPrice) {
    return false;
  }
  return Math.abs(offerPrice - lowTotalPrice) > (lowTotalPrice * 0.05)
}

function renderOfferTableItem(offer, isOriginShown) {
  return (<a
    className={cn(styles.offerTableItem)}
    onClick={() => openOfferDetails(offer)}
  >
    <div className={styles.offerName} title={offer.destination.name}>
      {isOriginShown && (offer.origin.name + ' - ')}
      {offer.destination.name}
    </div>
    <div className={styles.offerAirlineImage}>
      {offer.airline.logo && (
        <img
          src={offer.airline.logo.replace('http', 'https')
            .replace('width=108&height=92', 'width=30&height=25') }
          title={
            offer.airline.name +
            ' (' +
            offer.airline.code +
            ')'
          }
          alt='Airline Logo'
        />
      )}

      {!offer.airline.logo && (
        <span
          title={
            offer.airline.name +
            ' (' +
            offer.airline.logo +
            ')'
          }
        >
          {offer.airline.name}
        </span>
      )}
    </div>
    <div className={styles.offerDate}>
      {moment(offer.departureDate, 'YYYY-MM-DD').format(
        'D MMM'
      )}{' '}
      -{' '}
      {moment(offer.returnDate, 'YYYY-MM-DD').format(
        'D MMM'
      )}

      {offer.similarOffers?.length > 0 && (
        <span className={styles.similarOffersCount} title={'+' + offer.similarOffers.length + ' similar offers'}>
          +{offer.similarOffers.length}
        </span>
      )}
    </div>
    <div className={styles.offerPrice}>
      <div title={'from ' + getSymbolFromCurrency(offer.currency) + Math.round(offer.price)}>
        <span className={cn(styles.fromLabel, 'text-xs text-gray-500')}>From</span>{' '}
        <CurrencyFormat
          className={styles.price}
          thousandSeparator
          displayType={'text'}
          prefix={getSymbolFromCurrency(offer.currency) + ' '}
          value={Math.round(offer.price)}
        />
      </div>
    </div>
  </a>)
}

function generateOffer(offer, airline) {
  if (!offer) {
    return;
  }

  return {
    from: offer.origin.code,
    to: offer.destination.code,
    dateRange: {
      departure: offer.departureDate || null,
      return: offer.returnDate || null,
    },
    cabinClass: offer.cabinClass,
    tripState: 'round_trip',
    airlineType: airline,
    passengers: { adults: 1, children: 0 },
  };
}

function openOfferDetails(offer) {
  setIsOfferDialogOpen(true);
  sendFlightOptionsClickEvent(offer, offer.cabinClass);
  // setSelectedOffersFilters({
  //   cabinClass:offer.cabinClass
  // });
  setIsSimilarOffersLoading(true);
  getSimilarOffers({
    origin: offer.origin.code,
    destination: offer.destination.code,
    cabinClass: offer.cabinClass,
    currency: offer.currency || userStore?.currency,
    country: offer.country || userStore?.country,
  }).then((res) => {
    setIsSimilarOffersLoading(false);
    setSimilarOffers(res);
    if (res.chart?.length > 5) {
      setIsBarChartShown(true);
      const flights = createChartDataset(res.chart || []);
      setDataSets(flights);
    }
  });
}

  return (
    <>
      {offer && type === 'card' && (
        <div
          className={cn(styles.offerCard, offer?.similarOffers?.length > 0 && styles.similarOffers,  'cursor-pointer', (!offer?.destination?.region || !offer?.destination?.code) && userStore?.is_admin && 'bg-red-100')}
          onClick={() => {
            openOfferDetails(offer);
          }}
        >
        <div className={styles.offerImage}>
          <ImageWithFallback
            src={setImageUrl(offer.destination.image)}
            width={400}
            height={400}
            objectFit="cover"
            alt={offer.destination.name}
            quality={95}
            sizes="(max-width: 1024px) 100vw, 400px"
          />
          {/* {(!process.env.prod || process.env.prod === 'false') && offer.isDeal && (
            <Chip label="Deal" size="small" color="primary" variant='outlined' className='mt-3 ml-6' />
          )} */}

          {userStore?.is_admin && offer.idOffer && (
            <Chip label="Offer" size="small" color="success" variant='outlined' className='mt-3 ml-6'/>
          )}
        </div>
        <div className={styles.offerData}>
          {offer.airline?.code && (
            <div className={cn(styles.offerOverline, 'flex')}>
              <p className={cn(styles.offerOverline, 'w-full m-0 p-0')}>
                <span className={styles.airlineName}>
                  {' '}
                  {getCabinClass(offer.cabinClass, false)}
                </span>
                {' | '}
                {offer.airline.name}
                <br />
                {offer.origin.name} ({offer.origin.code}) -{' '}
                {offer.destination.name} ({offer.destination.code})
                <br />
              </p>

              <div className={styles.airlineLogo}>
                <img
                  src={offer.airline.logo?.replace('http', 'https')}
                  width={40}
                  height={35}
                  loading="lazy"
                  alt="Airline logo"
                  className="ml-auto"
                />
              </div>
            </div>
          )}

          <h3 className={cn("font-fragment my-3", showOrigin && 'text-lg')}>
            {showOrigin && offer.origin.name + ' - ' + offer?.destination.name}
          </h3>
          {(offer?.departureDate || offer?.returnDate) && (
            <span className={cn("text-xs text-black")}>
              {moment(offer?.departureDate).format(
                'D. MMM'
              )}{' '}
              - {moment(offer?.returnDate).format('D. MMM yyyy')}
            </span>
          )}

          <div className={cn(styles.offerPrice, 'font-libre')}>
            <i className="mr-1">From</i>{' '}
            <CurrencyFormat
              thousandSeparator
              displayType={'text'}
              prefix={getSymbolFromCurrency(offer.currency) + ' '}
              value={Math.round(offer.price || offer.lowTotal)}
              className="mr-2"
            />
            {offer.regularPrice && (
              <>
                <span className={cn(styles.regularPrice, 'line-through mr-2')}>
                  <CurrencyFormat
                    thousandSeparator
                    displayType={'text'}
                    prefix={getSymbolFromCurrency(offer.currency) + ' '}
                    value={offer.regularPrice}
                  />
                </span>{' '}
                <span className={styles.regularPrice}>
                  (
                  {Math.round(
                    ((offer.regularPrice - offer.price) / offer.regularPrice) *
                      100
                  ) + '% off'}
                  )
                </span>
              </>
            )}
          </div>

          {offer.searchDate && (
            <div className='text-xs text-gray-600 mt-2'>
              Offer found {hoursAgo(offer.searchDate)}{' '}
            </div>
          )}

          {(offer.lowTotal || offer.lowTotalAirline) && userStore?.is_admin && (
            <div className='text-xs text-gray-600'>
              {offer.lowTotal && (
                <span>Low total: <b>{offer.lowTotal?.toFixed(0)}</b></span>
              )}
              {isPriceMismached(offer.price, offer.airline.lowTotal || offer.lowTotal) && (
                <span className='bg-red-500 text-xs text-white inline-block px-1 rounded-sm'>! Price difference more than 5%</span>
              )}
              <br />
              {offer.airline.lowTotal && (
                <span>Low total airline: <b>{offer.airline.lowTotal.toFixed(0)}</b></span>
              )}
            </div>
          )}

          {offer.similarOffers?.length > 0 && (
            <div className={cn(styles.offerOverline, 'mt-1s')}>
              <b>+{offer.similarOffers?.length}</b> similar offers
            </div>
          )}

          {userStore?.is_admin && (
            <div>
              {!offer?.destination?.region && (
                <span className='text-red-600 text-xs'>No region specified</span>
              )}

              {!offer?.destination?.code && (
                <span className='text-red-600 text-xs'>No destination code specified</span>
              )}
            </div>
          )}

          {/* <IconButton
                color="inherit"
                className={styles.shareIcon}
                onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setShareDialogState({ open: true, offer });
                }}
                aria-label="close"
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '5px',
                }}
                >
                <IosShareIcon className="m-0 lg:h-6 lg:w-6 -mt-1" />
            </IconButton> */}
        </div>
      </div>
      )}

      {offer && type === 'table' && (
        renderOfferTableItem(offer, width >= 1024 ? showOrigin : false)
      )}

      <Drawer
        open={isOfferDialogOpen}
        onClose={() => closeOfferDialog()}
        anchor={'right'}
        ModalProps={{
          disableAutoFocus: true,
          disableEnforceFocus: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '60%',
            backgroundColor: '#FAF8F4',
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          },
        }}
      >
        {/* sx={{ '& .MuiDrawer-paper': { width: '60%', backgroundColor: '#FAF8F4' } }} */}

        {!offer && (
          <div className={styles.loader}></div>
        )}

        {offer && (
          <div
            className={cn(
              styles.offerDialogContent,
              styles.offers,
              'p-3 lg:p-6 lg:pt-20 pt-16'
            )}
          >
            <div className="flex">
              <IconButton
                color="inherit"
                onClick={() => closeOfferDialog()}
                aria-label="close"
                className={styles.closeDialog}
              >
                <CloseIcon />
              </IconButton>

              <h3 className="text-3xl w-full font-libre">Offer details</h3>
            </div>

            {message && (
              <div className={cn(styles.chooseDatesHint, 'mb-2')}>
                {message}
              </div>
            )}

            <div
              className={cn(
                styles.offerDetailsContainer,
                'relative grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4'
              )}
              id='offer-details'
            >
              <div
                className={cn(
                  styles.offerDetailsImageContainer,
                  'relative max-w-full'
                )}
              >
                <ImageWithFallback
                  src={setImageUrl(offer?.destination.image)}
                  layout="fill"
                  objectFit="cover"
                  alt={offer?.destination.name}
                  quality={95}
                  sizes="(max-width: 1024px) 100vw, 400px"
                  className="h-40"
                />
              </div>

              <div className={cn(styles.offerData, 'w-full')}>
                {offer.airline?.code && (
                  <div className={cn(styles.offerOverline, 'flex')}>
                    <p
                      className={cn(
                        styles.offerOverline,
                        'w-full m-0 p-0 lg:text-base'
                      )}
                    >
                      <span className="font-bold">
                        {' '}
                        {getCabinClass(offer.cabinClass, false)}
                      </span>
                      {' | '}
                      {offer.airline.name}
                      <br />
                      {offer.origin.name} ({offer.origin.code}) -{' '}
                      {offer.destination.name} ({offer.destination.code})
                    </p>

                    <div>
                      <img
                        src={offer.airline.logo?.replace('http', 'https')}
                        width={50}
                        height={46}
                        loading="lazy"
                        alt="Airline logo"
                        className="ml-auto"
                      />
                    </div>
                  </div>
                )}
                <h3 className={cn("font-fragment text-left my-2 lg:my-5", showOrigin ? 'text-2xl' : 'text-3xl')}>
                  {showOrigin && offer.origin.name  + ' - '}{offer?.destination.name}
                </h3>

                <div
                  className={cn(
                    styles.offerPrice,
                    'text-2xl',
                    !offer.regularPrice && 'mb-4'
                  )}
                >
                  <i className="mr-2 text-base">From</i>{' '}
                  <CurrencyFormat
                    thousandSeparator
                    displayType={'text'}
                    prefix={getSymbolFromCurrency(offer?.currency) + ' '}
                    value={Math.round(offer?.price)}
                    className="mr-2"
                  />
                </div>

                {offer.regularPrice && (
                  <div className="text-base mb-4">
                    <span
                      className={cn(
                        styles.regularPrice,
                        'line-through mr-2 text-lg'
                      )}
                    >
                      <CurrencyFormat
                        thousandSeparator
                        displayType={'text'}
                        prefix={getSymbolFromCurrency(offer.currency) + ' '}
                        value={offer.regularPrice}
                      />
                    </span>{' '}
                    <span className={cn(styles.regularPrice, 'text-lg')}>
                      (
                      {Math.round(
                        ((offer.regularPrice - offer.price) /
                          offer.regularPrice) *
                          100
                      ) + '% off'}
                      )
                    </span>
                  </div>
                )}

                <div className='flex w-full items-center mb-2'>
                  <Button
                    color='primary'
                    type='button'
                    onClick={() => startSearchFromOffer(offer)}
                    className={cn(styles.offerLink, 'mr-2')}
                  >
                    View offer
                  </Button>

                  {offer.idOffer && userStore?.is_admin && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      className="ml-auto rounded-none bg-transparent text-black border-black hover:bg-black hover:text-white"
                      type="button"
                      onClick={() => {
                        window.open(
                          `/admin-v2/offers/offer/edit/${offer.idOffer}`,
                        )
                      }}>
                      Edit offer
                    </Button>
                  )}
                </div>
                

                <div className={cn(styles.offerOverline, 'mb-2')}>
                  {offer?.expirationDate && (
                    <p className="text-lg">
                      Offer expires:{' '}
                      <b>{moment(offer?.expirationDate).format('DD.MM.YYYY')}</b>
                    </p>
                  )}
                  {(offer?.departureDate || offer?.returnDate) && (
                    <p className="text-lg">
                      Travel dates:{' '}
                      <b>
                        {moment(offer?.departureDate).format('DD.MM.YYYY')} -{' '}
                        {moment(offer?.returnDate).format('DD.MM.YYYY')}
                      </b>
                    </p>
                  )}
                </div>
                
              </div>

              <Tooltip title="Share offer" placement="top">
                <IconButton
                    color="inherit"
                    className={styles.shareIcon}
                    onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setShareDialogState({ open: true, offer });
                    }}
                    aria-label="close"
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: '5px',
                    }}
                    >
                    <IosShareIcon className="m-0 lg:h-6 lg:w-6 -mt-1" />
                </IconButton>
              </Tooltip>
            </div>

            {isFlightSearchLink(offer?.link) && (
              <div className={cn(styles.offerDetailsContainer, 'mb-4')}>
                <h4 className="text-left font-libre mb-0">
                  <TravelExploreOutlinedIcon className="mr-2" /> Start offer
                  flight search
                </h4>
                {/* <p>
                  Select the dates and the number of passengers to start your
                  flight search
                </p> */}

                <InView triggerOnce>
                  {({ inView, ref }) => (
                    <div ref={ref} className="w-full">
                      {inView && (
                        <FlightSearch
                          collapsed={false}
                          disablePortal={false}
                          airlineName={offer.airline?.name}
                          airlineCode={offer.airline?.code}
                          airlineClass={
                            CABIN_CLASSES.find(
                              (cabin) => cabin.value === offer.cabinClass
                            ).label
                          }
                          flightSearchValueProp={generateOffer(
                            offer,
                            offer.airline?.code
                          )}
                          // minDate={offer?.departureDate}
                          // maxDate={offer?.returnDate}
                          defaultSortBy={'price'}
                          viewMode={width > 1600 ? 'mainSearch' : 'sideSearch'}
                          customHeader={
                            <span className="text-sm font-semibold font-libre leading-5 inline-block">
                              We’ve pre-filled some suggested dates, but feel free to change them and click &apos;Find offer&apos; to see more flight offers.
                            </span>
                          }
                          customSearchText={'Find offer'}
                          openInNewTab={true}
                          searchSource={searchSource}
                        />
                      )}
                    </div>
                  )}
                </InView>
              </div>
            )}

            {isSimilarOffersLoading && <BorderLinearProgress />}

            {similarOffers?.offers && similarOffers?.offers.length > 0 && (
              <div className={cn('mb-4')} id="similarOffers">
                <div className='flex'>
                  <h4 className="text-left font-libre mb-0 flex items-center w-full">
                    <ListAltOutlinedIcon className="mr-2" /> Similar offers to {offer.destination.name} from other airlines 
                    {clickedBarMonth && (
                      <span>&nbsp;in <b style={{color: '#00454A'}}>{clickedBarMonth}</b>
                      </span>
                    )}
                  </h4>
                  {clickedBarMonth && (
                    <span className='text-sm cursor-pointer underline ml-auto flex mt-1 font-semibold' onClick={() => handleMonthClick(null)}>Reset</span>
                  )}
                </div>
                

                {/* <div className={'px-6 lg:px-10'}>
                  <FeaturedFlightsFilters
                    emitFilters={emitPopularDealsFilters}
                    initialFilters={selectedOffersFilters}
                    // airlines={monthPopularDeals?.airlines}
                    minBudget={featuredMinBudget}
                    maxBudget={featuredMaxBudget}
                    currency={userStore.currency}
                    isLightMode={true}
                    isSorting={false}
                    isCabinClassFilterShown={true}
                    // defaultMonth={(clickedBarMonth - moment().get('month'))}
                  ></FeaturedFlightsFilters>
                </div> */}

                <div
                  className={cn(
                    'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4'
                  )}
                >
                  {visibleOffers.map((similarOffer) => (
                    similarOffer.id !== offer.id && (
                      <div
                        key={similarOffer.searchDate + '_' + similarOffer.price}
                        className={cn(
                          styles.offerData,
                          styles.offerDetailsContainer,
                          'w-full cursor-pointer'
                        )}
                      >
                        <div className={cn(styles.offerOverline, 'flex')}>
                          <span className="font-bold block">
                            {getCabinClass(similarOffer.cabinClass, true)}
                          </span>
                          <span className="px-1">|</span>
                          <span> {similarOffer.airline.name}</span>

                          <img
                            src={similarOffer.airline.logo?.replace('http', 'https')}
                            width={25}
                            height={20}
                            loading="lazy"
                            alt="Airline logo"
                            className="ml-auto"
                          />
                        </div>
                        {(similarOffer?.departureDate || similarOffer?.returnDate) && (
                          <div className={styles.offerOverline}>
                            <p className="text-sm">
                              <b>
                                {moment(similarOffer?.departureDate).format(
                                  'DD.MM.YYYY'
                                )}{' '}
                                - {moment(similarOffer?.returnDate).format('DD.MM.YYYY')}
                              </b>
                            </p>
                          </div>
                        )}
                        <div
                          className={cn(
                            styles.offerPrice,
                            'text-xl mt-2 lg:mt-4'
                          )}
                        >
                          <i className="mr-2 text-base">From</i>{' '}
                          <CurrencyFormat
                            thousandSeparator
                            displayType={'text'}
                            prefix={getSymbolFromCurrency(similarOffer?.currency || userStore?.currency) + ' '}
                            value={Math.round(similarOffer?.airline.lowTotal || similarOffer?.price)}
                            className="mr-2"
                          />
                        </div>

                        {similarOffer.regularPrice && (
                          <div className="text-base">
                            <span
                              className={cn(
                                styles.regularPrice,
                                'line-through mr-2 text-base'
                              )}
                            >
                              <CurrencyFormat
                                thousandSeparator
                                displayType={'text'}
                                prefix={
                                  getSymbolFromCurrency(similarOffer.currency) + ' '
                                }
                                value={similarOffer.regularPrice.toFixed(2)}
                              />
                            </span>{' '}
                            <span className={cn(styles.regularPrice, 'text-lg')}>
                              (
                              {Math.round(
                                ((similarOffer.regularPrice - similarOffer.price) /
                                similarOffer.regularPrice) *
                                  100
                              ) + '% off'}
                              )
                            </span>
                          </div>
                        )}

                        {userStore?.is_admin  && (
                          <div className='flex'>
                            <div>
                            {similarOffer.lowTotal && (
                              <span className='text-xs block text-gray-400'>Lowest: {Math.round(similarOffer.lowTotal)}</span>
                            )}

                            {similarOffer.airline.lowTotal && (
                              <span className='text-xs block text-gray-400'>Airline: {Math.round(similarOffer.airline.lowTotal)}</span>
                            )}
                            </div>

                            <div className='ml-auto'>
                              {similarOffer.idOffer && (
                                <Chip label="Offer" size="small" color="success" variant='outlined' />
                              )}
                            </div>
                          </div>
                        )}

                        <Button
                          onClick={() => {
                            startSearchFromOffer(similarOffer);
                          }}
                          className={cn('h-full, mt-4 cursor-pointer')}
                          sx={{ textTransform: 'unset', backgroundColor: '#3C6562', color: '#fff', "&:hover": { backgroundColor: '#00454A !important' } }}
                          fullWidth
                          color="primary"
                          variant="contained"
                          type="button"
                        >
                          Find offer
                        </Button>
                      </div>
                    )
                  ))}
                </div>

                
                {visibleCount < similarOffers?.offers?.length && (
                  <Button
                    onClick={() => {
                      setVisibleCount((prev) => prev + PAGE_SIZE);
                    }}
                    className={cn('h-full, mt-4 cursor-pointer')}
                    sx={{ textTransform: 'unset', borderColor: '#3C6562', color: '#3C6562', "&:hover": { backgroundColor: '#3C6562 !important', color: '#fff' } }}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    type="button"
                  >
                    Load more similar offers
                  </Button>
                 )}
              </div>
            )}

            {(!similarOffers?.offers && isSimilarOffersLoading) && (
              <div className="grid grid-cols-3 gap-6">
                {Array.from(new Array(6)).map((item, index) => (
                  <Box
                    key={index}
                    sx={{ width: 210, marginRight: 0.5, my: 5 }}
                  >
                    {/* <Skeleton variant="rectangular" width={210} height={118} /> */}
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton height={30} />
                      <Skeleton width="60%" height={30} />
                    </Box>
                  </Box>
                ))}
              </div>
            )}

            {(!similarOffers?.offers?.length && !isSimilarOffersLoading) && (
              <h3 className="text-center font-bold my-4 ">
                No similar offers found
              </h3>
            )}

            {offer.isDeal && dataSets && isBarChartShown && (
              <div className={cn(styles.offerDetailsContainer, 'mb-4')}>
                <h4 className="text-left font-libre">
                  <DiscountIcon className="mr-2" /> Best deals price variations by departure month
                </h4>
                <div className={styles.chartContainer}>
                  <OfferChart dataSets={dataSets} monthClick={handleMonthClick} />
                </div>
                <span className={cn(styles.hint)}>
                  <b>*Click on bar chart month to show more cheap offers{' '}</b>
                </span>
                <br />
                <span className={cn(styles.hint)}>
                  **All prices are in {userStore.currency}
                </span>
                  
                {/* {openFeaturedFlightDialog?.country !== userStore?.country && (
                  <p className={styles.hint}>
                    ***You are viewing shared deal for {openFeaturedFlightDialog?.country} which is not your selected country. The country and prices will be changed after you close this dialog
                  </p>
                )} */}
              </div>
            )}

            {offer.content && (
              <div className={cn(styles.offerDetailsContainer, 'mb-4')}>
                <h4 className="text-left font-libre">
                  <DescriptionOutlinedIcon className="mr-2" /> Offer description,
                  terms and conditions
                </h4>
                <div>{parse(offer.content, options)}</div>
              </div>
            )}

            <div className={styles.offerDetailsContainer}>
              <h4 className="text-left font-libre">
                <AnnouncementOutlinedIcon className="mr-2" /> Important notes
              </h4>
              <p>
                Amazing fares like these don’t last long - they can disappear
                within hours, or even minutes! If you don’t see similar prices
                to those listed above, try selecting different travel dates to
                find other great options. And don’t worry, we’ve got plenty more
                incredible deals coming your way very soon. Stay tuned!
              </p>
            </div>
          </div>
        )}
      </Drawer>

      <Dialog
        open={shareDialogState.open && shareDialogState.offer}
        onBackdropClick={() => {
          setShareDialogState({ open: false, offer: null });
        }}
        PaperProps={{
          style: { position: 'relative', minWidth: '30vw' },
        }}
      >
        <IconButton
          color="inherit"
          onClick={() => {
            setShareDialogState({ open: false, offer: null });
          }}
          aria-label="close"
          className={styles.closeShareDialog}
        >
          <CloseIcon />
        </IconButton>

        <div className="relative">
          <div className={styles.imageHeader}>
            {shareDialogState.offer && (
              <ImageWithFallback
                src={
                  setImageUrl(shareDialogState.offer.destination.image)
                }
                width={1200}
                height={400}
                quality={50}
                objectFit="cover"
              />
            )}
          </div>

          {shareDialogState.offer && (
            <h3
              className={cn(
                styles.imageTitle,
                'font-fragment font-normal text-xl lg:text-3xl'
              )}
            >
              {shareDialogState.offer.origin.name} - {
                shareDialogState.offer.destination.name}
            </h3>
          )}
        </div>

        <div className="p-4 mt-3">
          <h4 className='font-libre'>Share this offer</h4>

          <div className="flex align-items-center">
            <TextField
              label="Share url"
              variant="outlined"
              defaultValue={`${process.env.apiUrl}${getOfferUrl(shareDialogState.offer)}`}
              className="w-full"
              inputProps={{ readOnly: true }}
            />
            <Button
              variant="contained"
              color="secondary"
              sx={{
                backgroundColor: '#0a0a0a',
                color: '#ffffff',
                marginLeft: '1rem',
                textTransform: 'none',
                minWidth: '100px',

                '&:hover': {
                  backgroundColor: '#ffffff',
                  color: '#0a0a0a',
                },
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.apiUrl}${getOfferUrl(shareDialogState.offer)}`
                );
                setShareDialogState({ open: false, offer: null });
              }}
            >
              Copy link
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
